/* NOTE(azirbel):
 * Breakpoints (synced with flexboxgrid.css):
 *
 * sm begins at 736px
 * md begins at 980px
 * lg begins at 1280px
 */

* {
  box-sizing: border-box;
}

html, body {
  font-weight: normal;
  font-size: 18px;
  margin: 0;
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}

/* Put a container inside me */
section.splash {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

section.splash > * {
  width: 100%;
}

section.light-gray {
  background-color: #eee;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.container {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.container.small {
  max-width: 540px;
}

.container.large {
  max-width: 1100px;
}

/* More readable 2-column text for flexboxgrid */
.padded-col {
  padding-right: 24px !important;
}

@media only screen and (max-width: 735px) {
  .hidden-xs-down { display: none; }
}

@media only screen and (min-width: 736px) {
  .hidden-sm-up { display: none; }
}

@media only screen and (min-width: 979px) {
  .hidden-sm-down { display: none; }
}

@media only screen and (min-width: 980px) {
  .hidden-md-up { display: none; }
}

@media only screen and (min-width: 1279px) {
  .hidden-md-down { display: none; }
}

@media only screen and (min-width: 1280px) {
  .hidden-lg-up { display: none; }
}
